import axios from 'axios';
import { useState, useEffect, useCallback } from 'react';

export interface EmpireDropDeposit {
    rank: number;
    total: number;
    total_value: string;
    currency: string;
    active: boolean;
    user: {
        hash_id: string;
        name: string;
        avatar: string;
        level: number;
    };
}

export interface EmpireDropResponse {
    cache_duration: number;
    ranking: EmpireDropDeposit[];
    startDate: string;
    endDate: string;
    cachedUntil: string;
}

export const useGetEmpireDropDeposits = (fetchPrev = false) => {
    const [deposits, setDeposits] = useState<EmpireDropDeposit[]>([]);
    const [loading, setLoading] = useState<boolean>(true);
    const [error, setError] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<string | null>(null);
    const [endDate, setEndDate] = useState<string | null>(null);
    const [cachedUntil, setCachedUntil] = useState<string | null>(null);

    const fetchEmpireDropDeposits = useCallback(async () => {
        const endpoint = fetchPrev ? '/api/empiredrop/prev' : '/api/empiredrop/current';
        try {
            const response = await axios.get<EmpireDropResponse>(`${process.env.REACT_APP_STRAPI_URL}${endpoint}`, {
                headers: {
                    'Authorization': `Bearer ${process.env.REACT_APP_STRAPI_API_KEY}`,
                    'Content-Type': 'application/json'
                }
            });
            setDeposits(response.data.ranking);
            setStartDate(response.data.startDate);
            setEndDate(response.data.endDate);
            setCachedUntil(response.data.cachedUntil);
        } catch (err: any) {
            setError(err.message || 'An error occurred while fetching the data');
        } finally {
            setLoading(false);
        }
    }, [fetchPrev]);

    useEffect(() => {
        fetchEmpireDropDeposits();
        // eslint-disable-next-line
    }, []);

    return { deposits, loading, error, startDate, endDate, cachedUntil, fetchEmpireDropDeposits };
};
