import CountdownTimer from "../components/Countdown";
import Loader from "../components/Loader";
import { useGetConversionRates } from "../hooks/useGetConversionRates";
import { EmpireDropDeposit, useGetEmpireDropDeposits } from "../hooks/useGetEmpireDropDeposits";
import '../static/css/EmpireDrop.css'
import { toast } from "react-toastify";
import { Helmet } from "react-helmet";

export const EMPIREDROP_MIN_DEPOSIT = 5500;

export default function EmpiredropDeposits() {
    const currentWeek = useGetEmpireDropDeposits();
    const previousWeek = useGetEmpireDropDeposits(true);
    // eslint-disable-next-line
    const { conversionRate, loading: conversionLoading, error: conversionError } = useGetConversionRates();

    if (currentWeek.loading || previousWeek.loading || conversionLoading) return <Loader />;

    if (currentWeek.error) {
        toast.error(currentWeek.error);
        return <div>{currentWeek.error}</div>;
    }
    if (previousWeek.error) {
        toast.error(previousWeek.error);
        return <div>{previousWeek.error}</div>;
    }
    if (conversionError) {
        toast.error(conversionError);
        return <div>{conversionError}</div>;
    }

    const renderDeposits = (data: any, title: string) => (
        <div className={`tier ${title.toLowerCase().replace(' ', '-')} ${title === "Current Week" ? 'border-[1px] border-[#6826FD]' : ''}`}>
            <h2 className="tier__title">{title}</h2>
            <p className="tier__subtitle">
                {title === "Current Week" ? (
                    <CountdownTimer endDate={data.endDate} />
                ) : (
                    `From ${new Date(data.startDate).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })} to ${new Date(data.endDate).toLocaleString('en-US', { year: 'numeric', month: 'long', day: 'numeric', hour: '2-digit', minute: '2-digit', hour12: true })}`
                )}
            </p>
            <table className="tier__deposits-table">
                <thead>
                    <tr>
                        <th>User</th>
                        <th>Wagered</th>
                    </tr>
                </thead>
                <tbody>
                    {data.deposits?.map((deposit: EmpireDropDeposit) => (
                        <tr key={deposit.user.hash_id} style={{ borderBottom: '1px solid #333' }}>
                            <td className={`${deposit.total / 100 >= EMPIREDROP_MIN_DEPOSIT ? 'text-green-500' : 'text-white'}`}>{deposit.user.name}</td>
                            {/* <td>${(deposit.total * conversionRate / 100).toFixed(2)}</td> */}
                            {/* No conversion */}
                            <td className={`${deposit.total / 100 >= EMPIREDROP_MIN_DEPOSIT ? 'text-green-500' : 'text-white'}`}>€{(deposit.total / 100).toFixed(2)}</td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </div>
    );

    return (
        <>
            <Helmet>
                <title>Empiredrop Deposits | Exodia Rewards</title>
                <meta name="description" content="Deposit on EmpireDrop and climb above the threshold for a chance to win prizes!" />
                <link rel="canonical" href="https://exodiarewards.com/empiredrop/deposits" />
            </Helmet>
            <section id="empiredrop">
                <div className="custom__container">
                    {currentWeek.cachedUntil && <p>The board will update in <CountdownTimer endDate={currentWeek.cachedUntil!} whenFinished={currentWeek.fetchEmpireDropDeposits} /></p>}
                    <br />
                    <div className="tier__wrapper">
                        {renderDeposits(previousWeek, "Previous Week")}
                        {renderDeposits(currentWeek, "Current Week")}
                    </div>
                </div>
            </section>
        </>
    );
}
