import React, { useState, useEffect, useRef } from 'react';
import '../static/css/Navbar.css';
import { Link } from 'react-router-dom';
import logo from '../static/logo.png';
import { CaretDownFilled, CaretUpFilled } from '@ant-design/icons';
import csgobig from '../static/csgobig.png';
import datdrop from '../static/datdrop.png';
import empiredrop from '../static/empiredrop.png';
import steamLoginImage from '../static/img/steam.png';
import { useAuth } from '../context/AuthContext';
import Loader from './Loader';
import { Balance } from './Balance';
import { EMPIREDROP_MIN_DEPOSIT } from '../pages/EmpiredropDeposits';
import xfunLogoImage from '../static/img/xfun-logo.webp';
import xfunLogo from '../static/img/xfun-logo.svg';

export const Navbar = (): JSX.Element => {
    const { isAuthenticated, isLoading, user, isAdmin } = useAuth();
    const [isOpen, setIsOpen] = useState(false);
    const [scrolled, setScrolled] = useState(false);
    const [datdropOpen, setDatdropOpen] = useState(false);
    const [csgobigOpen, setCsgobigOpen] = useState(false);
    const [empiredropOpen, setEmpiredropOpen] = useState(false);
    const [pointsOpen, setPointsOpen] = useState(false);
    const datdropRef = useRef<HTMLLIElement>(null);
    const csgobigRef = useRef<HTMLLIElement>(null);
    const empiredropRef = useRef<HTMLLIElement>(null);
    const pointsRef = useRef<HTMLLIElement>(null);

    useEffect(() => {
        const handleResize = () => {
            if (window.innerWidth >= 768) {
                setIsOpen(false);
            }
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    useEffect(() => {
        if (isOpen) {
            document.body.style.overflow = 'hidden';
        } else {
            document.body.style.overflow = 'visible';
        }
    }, [isOpen]);

    useEffect(() => {
        const handleScroll = () => {
            setScrolled(window.scrollY > 0);
        };
        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            if (datdropRef.current && !datdropRef.current.contains(event.target)) {
                setDatdropOpen(false);
            }
            if (csgobigRef.current && !csgobigRef.current.contains(event.target)) {
                setCsgobigOpen(false);
            }
            if (empiredropRef.current && !empiredropRef.current.contains(event.target)) {
                setEmpiredropOpen(false);
            }
            if (pointsRef.current && !pointsRef.current.contains(event.target)) {
                setPointsOpen(false);
            }
        };

        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);

    return (
        <header className={scrolled ? 'scrolled' : ''}>
            <div className="custom__container">
                <nav>
                    <h1>
                        <Link to="/" className="logo__link" onClick={() => setIsOpen(false)}>
                            <img src={logo} alt="Exodia Rewards" height="50" className="h-12" />
                            <span className="font-bold hidden xl:block">Exodia Rewards</span>
                        </Link>
                    </h1>
                    <div className="flex items-center gap-2 lg:hidden">
                        {isAuthenticated && !isLoading && user && (
                            <div className="flex gap-2 items-center justify-center">
                                <Balance />
                                <Link to="/profile" onClick={() => setIsOpen(false)} className="mt-2">
                                    <img src={user.avatar} alt={user.personaname} height="30" width="30" className="avatar h-6" />
                                </Link>
                            </div>
                        )}
                    </div>
                    <div className={`menu-icon flex lg:hidden ${isOpen ? 'open' : ''}`} onClick={() => setIsOpen(!isOpen)}>
                        <div></div>
                        <div></div>
                        <div></div>
                    </div>
                    <ul className={isOpen ? 'nav-links open' : 'nav-links'}>
                        {isAdmin && (
                            <li>
                                <Link to="/admin" className="text-red-600 hover:underline hover:text-red-700" onClick={() => setIsOpen(false)}>
                                    Admin
                                </Link>
                            </li>
                        )}
                        {isAuthenticated && (
                            <li className="dropdown" ref={pointsRef}>
                                <div className="dropdown__toggle" onClick={() => setPointsOpen(!pointsOpen)}>
                                    Points {pointsOpen ? <CaretUpFilled /> : <CaretDownFilled />}
                                </div>
                                <ul className={`dropdown-menu ${pointsOpen ? 'show' : ''}`}>
                                    <li>
                                        <Link to="/points/daily" onClick={() => setIsOpen(false)} className="relative flex gap-2 hover:no-underline">
                                            Daily Free
                                            <span className=" bg-[--color-gold] text-black text-xs px-2 py-0.5 rounded-full">NEW</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/cases" onClick={() => setIsOpen(false)}>
                                            Cases
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/parlays" onClick={() => setIsOpen(false)}>
                                            Parlays
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/points" onClick={() => setIsOpen(false)}>
                                            How to Earn?
                                        </Link>
                                    </li>
                                </ul>
                            </li>
                        )}
                        <li>
                            <Link to="/xfun/wager" className="flex items-center gap-2 hover:opacity-75 transition-all" onClick={() => setIsOpen(false)}>
                                <img src={xfunLogoImage} alt="X.FUN" className="h-6" />
                                <img src={xfunLogo} alt="X.FUN" className="h-5 mb-[2px]" />
                            </Link>
                        </li>
                        <li className="dropdown" ref={empiredropRef}>
                            <div className="dropdown__toggle" onClick={() => setEmpiredropOpen(!empiredropOpen)}>
                                <img src={empiredrop} alt="EmpireDrop" height="30" className="h-7" />
                                {empiredropOpen ? <CaretUpFilled /> : <CaretDownFilled />}
                            </div>
                            <ul className={`dropdown-menu ${empiredropOpen ? 'show' : ''}`}>
                                <li>
                                    <Link to="/empiredrop/competition" onClick={() => setIsOpen(false)}>
                                        Competition
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/empiredrop" onClick={() => setIsOpen(false)}>
                                        Tickets
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/empiredrop/deposits" onClick={() => setIsOpen(false)}>
                                        €{EMPIREDROP_MIN_DEPOSIT} Wager comp
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="dropdown" ref={datdropRef}>
                            <div className="dropdown__toggle" onClick={() => setDatdropOpen(!datdropOpen)}>
                                <img src={datdrop} alt="Datdrop Competitions" height="25" className="h-6" />
                                {datdropOpen ? <CaretUpFilled /> : <CaretDownFilled />}
                            </div>
                            <ul className={`dropdown-menu ${datdropOpen ? 'show' : ''}`}>
                                <li>
                                    <Link to="/datdrop" onClick={() => setIsOpen(false)}>
                                        Free Battles
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/datdrop100" onClick={() => setIsOpen(false)}>
                                        $100 competition
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/datdrop500" onClick={() => setIsOpen(false)}>
                                        $500 competition
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li className="dropdown" ref={csgobigRef}>
                            <div className="dropdown__toggle" onClick={() => setCsgobigOpen(!csgobigOpen)}>
                                <img src={csgobig} alt="CSGOBig Rewards" height="25" className="h-6" />
                                {csgobigOpen ? <CaretUpFilled /> : <CaretDownFilled />}
                            </div>
                            <ul className={`dropdown-menu ${csgobigOpen ? 'show' : ''}`}>
                                {/* <li>
                                    <Link to="/cs2big/battles" onClick={() => setIsOpen(false)}>
                                        Free battles
                                    </Link>
                                </li> */}
                                <li>
                                    <Link to="/tiers" onClick={() => setIsOpen(false)}>
                                        Rewards
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/cashout" onClick={() => setIsOpen(false)}>
                                        Daily Cashout
                                    </Link>
                                </li>
                            </ul>
                        </li>
                        <li>
                            {isLoading && <Loader />}
                            {isAuthenticated && user && (
                                <div className="flex gap-2 items-center justify-center">
                                    <Balance />
                                    <Link to="/profile" onClick={() => setIsOpen(false)}>
                                        <img src={user.avatar} alt={user.personaname} height="30" width="30" className="avatar h-6" />
                                    </Link>
                                </div>
                            )}
                            {!isAuthenticated && (
                                <a href={`${process.env.REACT_APP_BASE_URL}/api/v1/auth/steam`} className="hover:opacity-75 transition-all">
                                    <img src={steamLoginImage} alt="Steam Login" height="25" className="h-6" />
                                </a>
                            )}
                        </li>
                    </ul>
                </nav>
            </div>
        </header>
    );
};
