import React, { useState, useEffect } from 'react';
import { initGA } from '../helpers/ga-utils';

const CookieConsent = () => {
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const consent = localStorage.getItem('cookieConsent');
        if (!consent) {
            setIsVisible(true);
        } else if (consent === 'accepted') {
            initGA(); 
        }
    }, []);

    const handleAccept = () => {
        localStorage.setItem('cookieConsent', 'accepted');
        setIsVisible(false);
        initGA(); 
    };

    const handleDecline = () => {
        localStorage.setItem('cookieConsent', 'declined');
        setIsVisible(false);
    };

    if (!isVisible) return null;

    return (
        <div className="fixed bottom-10 left-1/2 transform -translate-x-1/2 bg-[--bg-gray] border border-[--color-gold] rounded p-5 w-full max-w-md shadow-lg">
            <h2 className="text-lg font-semibold">We Value Your Privacy</h2>
            <p className="text-sm text-gray-300 mt-2">
                We use cookies to improve your experience and analyze site usage. By accepting, you allow us to use Google Analytics for these purposes.
            </p>
            <div className="flex gap-4 mt-4">
                <button
                    onClick={handleAccept}
                    className="bg-[--color-gold] text-black py-2 px-4 rounded hover:opacity-75 transition-all"
                >
                    Accept
                </button>
                <button
                    onClick={handleDecline}
                    className="bg-gray-700 text-white py-2 px-4 rounded hover:bg-gray-600 transition-all"
                >
                    Decline
                </button>
            </div>
        </div>
    );
};

export default CookieConsent;
