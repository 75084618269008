import { DiscordOutlined, XOutlined, YoutubeOutlined, TwitchOutlined } from '@ant-design/icons/lib/icons'
import '../static/css/Footer.css'
import { Kick } from '../static/kick'
import { Link } from 'react-router-dom'

export const Footer = (): JSX.Element => {
    return (
        <footer className="footer">
            <div className="custom__container">
                <div className="footer__content">
                    <div className="left flex flex-col">
                        <div className="socials">
                            <a href="https://twitter.com/ExodiaSecret" target="_blank" rel="noopener noreferrer"><XOutlined style={{ fontSize: '2rem', color: '#FFCC00' }} /></a>
                            <a href="https://www.youtube.com/@secretexodia" target="_blank" rel="noopener noreferrer"><YoutubeOutlined style={{ fontSize: '2rem', color: '#FFCC00' }} /></a>
                            <a href="https://discord.gg/Q7VEztD" target="_blank" rel="noopener noreferrer"><DiscordOutlined style={{ fontSize: '2rem', color: '#FFCC00' }} /></a>
                            <a href="https://www.twitch.tv/secretexodia" target="_blank" rel="noopener noreferrer"><TwitchOutlined style={{ fontSize: '2rem', color: '#FFCC00' }} /></a>
                            <a href="https://kick.com/secretexodia" target="_blank" rel="noreferrer" className="social__link">
                                <Kick />
                            </a>
                        </div>
                        <p className="copyright">Copyright &copy; 2024 - {new Date().getFullYear()}. Exodiarewards.com. All rights reserved.</p>
                    </div>
                    <div className="be__gamble__aware">
                        <div className="mb-3">
                            <Link to="/tos" className="hover:underline text-[--color-gold]">
                                Terms of Service
                            </Link>
                        </div>
                        <h4>Begambleaware</h4>
                        <p>
                            Responsible gambling advisory: while gambling can be entertaining, 
                            it’s crucial to be aware of the associated risks. Exercise self Control, 
                            set limits, and seek support if needed. 
                            Enjoy the excitement responsibly and always prioritize your well being.
                        </p>
                    </div>
                </div>
            </div>
        </footer>
    )
}